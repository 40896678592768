import { useState } from 'react';
import { TableCell, TableRow as MUITableRow, Fade } from '@mui/material';
import { Column, PercentCellProps, TableRowProps } from '../../types/types'
import Percent from '../accessory/Percent';
import moment from 'moment';

const TableRow = ({ row, columns, _onRowClick }: TableRowProps ) => {
  const [isHovering, setisHovering] = useState(false);

  const mainBackgroundColor = () => {
    if(isHovering){
      return 'var(--light-grayish-blue)'
    }else if (row.data.row_color){
      return row.data.row_color
    } return '#fff'
  }

  return (
    <Fade in={true} timeout={300}>
      <MUITableRow
        className={'hover:cursor-pointer transition duration-[85ms] ease-in-out '}
        style={{backgroundColor: '#fff'}}
        onMouseEnter={() => { setisHovering(true) }}
        onMouseLeave={() => { setisHovering(false) }}
        onClick={() => { if (_onRowClick)  _onRowClick(row)}}
      >
        {columns.map((col, idx) => {
          const cellData = row.data[col.key];
          const formattedData = formatData(col.label, cellData, col.type)
          const uniqueKey = `${row.id}-${col.key}`;
          return col.type === "percentage" 
            ? <TableCellPercent col={col} key={uniqueKey} percent={formattedData} bgColor={mainBackgroundColor()} />
            : <AssetCell col={col}  key={uniqueKey} formattedData={formattedData} color={row.data['color']} backgroundColor={mainBackgroundColor()}/>;
        })}
      </MUITableRow>
    </Fade>
  );
}

export default TableRow;

type AssetCellProps = {
  col: Column;
  formattedData: any;
  backgroundColor: string;
  color?: string | undefined
};



const AssetCell = ({ col, formattedData, backgroundColor, color } : AssetCellProps) => {

  const style: React.CSSProperties = {};
  if (col.isBold) {
    style.fontWeight = 'bold';
  }
  style.backgroundColor = backgroundColor;

  let className = '';
  if (col.key === 'name') {
    className += 'sticky left-0 z-10';
  }
  

  return (
    <TableCell align={col.isLeftAligned ? "left" : "right"} className={`${className} transition duration-[85ms] ease-in-out `} style={style}> 
      {col.key === 'asset' && color ? <CellWithIndicator color={color} data={formattedData}  />:formattedData}
    </TableCell>
  );
};

const TableCellPercent = ({ percent , col, bgColor}: PercentCellProps) => {
  return (
    <TableCell align={col.isLeftAligned ? "left" : "right"} className='transition duration-[85ms] ease-in-out '  style={{ fontWeight: col.isBold ? 'bold':'normal',background:bgColor ? bgColor:'' }}>
      <Percent percent={percent} />
    </TableCell>
  )
}

const CellWithIndicator = ({color,data}:{color:string|undefined,data:string|undefined})=>{
  return <div className='flex items-center'>
     <div style={{background:color}} className={`w-[9px] h-[9px] mr-[7.5px] md:mr-[5px]`} />
     {data}
  </div>
}

const formatDate = (dateString:string) => {
  const transactionDate = moment.utc(dateString);
  const twelveMonthsAgo = moment().subtract(12, 'months');
  return transactionDate.isAfter(twelveMonthsAgo) ?  transactionDate.format('MMM D, hh:mm A') : transactionDate.format('MMM DD, yyy');
}

const formatData = (label: string, value: any, type: string) => {
  if (value === undefined) {
    console.error(`Undefined value for label: ${label}, type: ${type}`);
    return '-';
  }
  switch (type) {
    case 'currency':
      return value < 0 ? `-$${Math.abs(value)}` : `$${value}`;
    case 'percentage':
      return `${value}%`;
    case 'number':
      return value;
    case 'date':
      return formatDate(value)
    case 'text':
    default:
      return value;
  }
};