import { TableData, NumberedTableData, LineDataPoint, ScatterChartDataPoint, ScatterChartData, ChartTimeFrame, LineChartData, Asset, Pm25Hourly, Pm25Daily } from '../types/types';

export const createMultiScatterChartData = () => {
  const airMeasure = createScatterChartData()
  const priceDividend = createScatterChartData();
  return {airMeasure, priceDividend}
}

export const createScatterChartData = (
  xAxisLabel: string = "[Sample] Price",
  yAxisLabel: string = "PM2.5 10 Minute Average",
  correlationValue: number = 0
): ScatterChartData => {
  const dataPoints = generateHighCorrelationData(1000)
  const assetMap: { [key: number]: string } = {};

  dataPoints.forEach((point) => {
    assetMap[point.assetId] = `Asset ${point.assetId}`;
  });

  return {
    xAxis: xAxisLabel,
    yAxis: yAxisLabel,
    assets: assetMap,
    data: dataPoints,
    correlation: correlationValue,
  };
};

const getRandomNumber = (min: number, max: number): number => {
  return Math.random() * (max - min) + min;
};

export const generateHighCorrelationData = (numPoints: number): ScatterChartDataPoint[] => {
  const data: ScatterChartDataPoint[] = [];
  for (let i = 0; i < numPoints; i++) {
    const x = getRandomNumber(1000, 5000);
    const y = x * (1 + getRandomNumber(0,2));
    const assetId = Math.round(getRandomNumber(1, 10));
    data.push({ x, y, assetId, cycleId: 1 });
  }
  return data;
};


export const generateDividendLineChartData = (timeFrame: ChartTimeFrame, numDataPoints: number = 100): LineChartData => {
  const dividendHistory = generateLineData(timeFrame, numDataPoints)
  const assetName = ""
  return { dividendHistory, assetName }
}

export const generatePriceLineChartData = (timeFrame: ChartTimeFrame, numDataPoints: number = 100): LineChartData => {
  const priceHistory = generateLineData(timeFrame, numDataPoints)
  const assetName = "Boulder"
  return { priceHistory, assetName }
}
// Function to generate data based on the given timeframe
export const generateLineData = (timeFrame: ChartTimeFrame, numDataPoints: number = 100): LineDataPoint[] => {
  const data: LineDataPoint[] = [];
  let currentDate = new Date();
  let currentPrice = 2700; // Initial price

  for (let i = 0; i < numDataPoints; i++) {
    const percentageFluctuation = (Math.random() * 2 - 1) / 100; // Fluctuate price by up to +/- 1%
    currentPrice *= (1 + percentageFluctuation);

    const dataPoint: LineDataPoint = {
      date: currentDate.toUTCString(),
      value: parseFloat(currentPrice.toFixed(4)), // Limit to 4 decimal places
    };

    data.push(dataPoint);

    switch (timeFrame) {
      case 'HOUR':
        currentDate = new Date(currentDate.getTime() + 60 * 1000); // Add 1 minute
        break;
      case '5HOUR':
        currentDate = new Date(currentDate.getTime() + 5 * 60 * 60 * 1000); // Add 5 hours
        break;
      case 'DAY':
        currentDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000); // Add 1 day
        break;
      case 'WEEK':
        currentDate = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000); // Add 1 week
        break;
    }
  }

  return data;
}

const generateRandompm25Value = (): number => {
  return Math.random() * (35 - 2) + 2;
}

export const generateAssetsData = () => {
    const assets: Asset[] = [];
    const cities = ["New York", "Los Angeles", "Chicago", "Houston", "Phoenix", "Philadelphia", "San Antonio", "San Diego", "Dallas", "San Jose"]
  
    for (let i = 0; i < cities.length; i++) {
      const asset: Asset = {
        id: i,
        name: cities[i],
        price: parseFloat((Math.random() * 2000).toFixed(2)),
        pm25: generateRandompm25Value(),
      };
    
      assets.push(asset);
    }
  
    return assets;
}

export const generatePm25HourlyData = (): Pm25Hourly[] => {
    const data = []
    for (let i = 0; i < 24; i++) {
      const asset: Pm25Hourly = {
        avg_pm_25: generateRandompm25Value(),
        hour_of_day: i,
      };
    
      data.push(asset);
    }
  
    return data;
}
export const generatePm25DailyData = (): Pm25Daily[] => {
    const data = []
    for (let i = 0; i < 7; i++) {
      const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
      const datapoint: Pm25Daily = {
        avg_pm_25: generateRandompm25Value(),
        index: i,
        day_of_week: days[i],
      };
    
      data.push(datapoint);
    }
  
    return data;
}
