import { getAssetTableData } from '../../api/api'
import Table from '../table/Table'
import { useEffect, useState } from 'react';
import { AssetHoldingsValuesData, Column } from '../../types/types';
import ErrorMessage from '../error/ErrorMessage';
import { useNavigate } from 'react-router-dom';

function AssetsTable() {
  const [data, setData] = useState<any[]>([]);
  const [columns, setColumns] = useState<Column[]>([]);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const fetchAssets = async () => {
    try {
      const datas = await getAssetTableData();
      if (datas !== null) {
        const { data, columns } = datas;
        setData(data);
        setColumns(columns)
      }
    } catch (error: any) {
      setError("Something went wrong! Failed to fetch assets table data");
    }
  }

  const _onRowClick = ( row : AssetHoldingsValuesData )=>{
    navigate(`/assets/${row.id}`)
  }

  useEffect(() => {
    fetchAssets();
  }, []);

  if (error) {
    return <ErrorMessage message={error} />
  }

  return (
    <Table data={data} _onRowClick={_onRowClick} columns={columns} setData={setData} />
  );
}

export default AssetsTable;