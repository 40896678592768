import { formatAmount, formatNumberTo2Decimal } from './utils';
import LoadingIndicator from './loadingitem';
import { modalInputClass, modalSubTextClass } from './consts';
import ModalDivider from '../accessory/ModalDivider';

interface SellAssetFormProps {
  currentPrice: number;
  cancelButtonRef: any;
  shares: number;
  formattedTotalPrice: string;
  totalSellPrice: number;
  balance: number;
  currentOwnedShares: number;
  error: string;
  successMessage: string;
  isLoading: boolean;
  handleSharesChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCloseModal: () => void;
  handleSell: () => void;
}

const SellAssetForm = ({
  currentPrice,
  cancelButtonRef,
  shares,
  formattedTotalPrice,
  totalSellPrice,
  balance,
  currentOwnedShares,
  error,
  successMessage,
  isLoading,
  handleSharesChange,
  handleCloseModal,
  handleSell
}: SellAssetFormProps) => {

  return (
    <>
      <div className="flex flex-row justify-between  mt-[57px] md:mt-[55px] items-center">
        <div className={`${modalSubTextClass} w-1/2`}>
          Shares
        </div>
        <div>
          <input
            type="text"
            inputMode="numeric"
            value={shares}
            onChange={handleSharesChange}
            className={`${modalInputClass}  w-[166px]`}
            pattern="[0-9]*"
          />
        </div>
      </div>
      <div className="flex flex-row justify-between mt-[57px] md:mt-[55px] relative">
          <div className={`${modalSubTextClass}`}>
            Market Price
          </div>
        <div className={`text-right ${modalSubTextClass}`}>
          {formatAmount(currentPrice)}
        </div>
        <ModalDivider />
      </div>
      <div className="flex flex-row justify-between mt-[57px] md:mt-[55px] relative">
        <div     className={`${modalSubTextClass}`}>
          Estimated Proceeds
        </div>
        <div className={`text-right ${modalSubTextClass}`}>
          {formattedTotalPrice}
        </div>
        <ModalDivider />
      </div>
      <div className="flex flex-row justify-between mt-[57px] md:mt-[55px] relative">
        <div  className={`${modalSubTextClass}`}>
          Shares Available
        </div>
        <div className={`text-right ${modalSubTextClass}`}>
          {formatNumberTo2Decimal(currentOwnedShares)}
        </div>
        <ModalDivider />
      </div>
      <div className="flex flex-row justify-between mt-[57px] md:mt-[55px] relative  mb-[57px] md:mb-[55px]">
        <div className={`${modalSubTextClass}`}>Cash Owned</div>
        <div className={`text-right ${modalSubTextClass}`}>
          {formatAmount(balance)}
        </div>
        <ModalDivider />
      </div>
      <div className='grid  grid-flow-col justify-center md:mt-6'>
        {error &&
          <span className="text-base font-semibold leading-6 text-[var(--warm-orange)]">
            {error}
          </span>
        }
      </div>
      <div className='grid grid-flow-col justify-center my-12'>
        {successMessage &&
          <span className="text-base font-semibold leading-6 text-[var(--turquoise-green)]">
            {successMessage}
          </span>
        }
      </div>
      <div className={`grid grid-cols-2 gap-4 grid-flow-col justify-between justify-stretch md:mt-4`}>
        <div className='w-full'>
          <button
            type="button"
            disabled={isLoading || shares === 0 || shares > currentOwnedShares}
            className={`inline-flex justify-center w-full rounded-[8px] md:rounded-[17px]
            border border-transparent shadow-sm px-4 py-3 
            p-2 ${isLoading || shares === 0 || shares > currentOwnedShares ? 'bg-[#ccc]':'bg-[var(--primary-dodger-blue)]'} 
            hover:opacity-[0.8] 
            text-white  text-[14px] md:text-[20px] font-bold`}
            onClick={() => handleSell()}
          >
            {isLoading && <LoadingIndicator />} SELL NOW
          </button>
        </div>

        <div className='w-full text-center'>
          <button
            type="button"
            className={`inline-flex justify-center w-full rounded-[8px] md:rounded-[17px]
            border-[#ccc] border-[1px] shadow-sm px-4 py-3 bg-white text-[14px] md:text-[20px] font-bold text-[var(--text-color-primary)] focus:outline-none md:text-md`}
            onClick={handleCloseModal}
            ref={cancelButtonRef}
          >
            CLOSE
          </button>
        </div>
      </div>
    </>
  )
}

export default SellAssetForm;