import { useState, useEffect } from "react";
import { getNextDividends } from "../../api/api";
import { useParams } from "react-router-dom";

export const useNextDividends = () => {
  const [nextDividends, setNextDividends] = useState<number | null>(null);
  const { assetId } = useParams<{ assetId: string }>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchDividends(setNextDividends,assetId);
  }, [assetId]);

  return [nextDividends, setNextDividends] as const;
};

const fetchDividends = async (
  setNextDividends: React.Dispatch<React.SetStateAction<number | null>>,
  assetId? : string | undefined
) => {
  try {
    const data = await getNextDividends(assetId);
    setNextDividends(data);
  } catch (error) {
    console.error("Failed to fetch dividends:", error);
  }
};

export const useUpdateDividends = (
  setNextDividends: React.Dispatch<React.SetStateAction<number | null>>
) => {
  useEffect(() => {
    const updateDividends = () => {
      setNextDividends((prev) => {
        if (prev === null) return null;
        const min = 0.00001;
        const max = 0.0002;
        const randomIncrement = Math.random() * (max - min) + min;
        const newValue = Math.round(
          Number(prev) + Number(prev) * randomIncrement
        );
        return newValue;
      });
    };

    const updateAtRandomInterval = () => {
      const min = 6000;
      const max = 20000;
      const randomInterval = Math.random() * (max - min) + min;
      const timer = setTimeout(() => {
        updateDividends();
        updateAtRandomInterval();
      }, randomInterval);
      return timer;
    };

    const timer = updateAtRandomInterval();
    return () => clearTimeout(timer);
  }, []);
};

export const useFetchDividends = (
  setNextDividends: React.Dispatch<React.SetStateAction<number | null>>,
  minutes: number,
  seconds: number
) => {
  useEffect(() => {
    if (minutes === 0 && seconds === 1) {
      fetchDividends(setNextDividends);
    }
  }, [minutes, seconds]);
};

export const useCountdownTime = () => {
  const now = new Date();
  const currentMinutes = now.getMinutes();
  const currentSeconds = now.getSeconds();

  // Find the nearest lower or equal multiple of 10 for minutes
  const nearestTenMinutes = Math.floor(currentMinutes / 10) * 10;

  // Initialize seconds and minutes
  const [seconds, setSeconds] = useState<number>(60 - currentSeconds);
  const [minutes, setMinutes] = useState<number>(
    nearestTenMinutes === currentMinutes
      ? 9
      : nearestTenMinutes + 9 - currentMinutes
  );

  // Handle seconds update
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        const newSeconds = (prevSeconds - 1 + 60) % 60;
        return newSeconds;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Handle minutes update
  useEffect(() => {
    if (seconds === 59) {
      setMinutes((prevMinutes) => (prevMinutes - 1 + 60) % 60);
    }
  }, [seconds]);

  useEffect(() => {
    if (minutes === 0 && seconds === 0) {
      // Reset to your desired initial values
      setMinutes(10);
      setSeconds(0);
    }
  }, [minutes, seconds]);
  return [minutes, seconds];
};

