import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import AssetCard from './components/chart/AssetCard';
import AirQuality from './components/airQuality/AirQuality';
import ErrorBoundary from './ErrorBoundary'
import Home from './components/home/home';
import MarketInfo from './components/marketInfo/MarketInfo';
import { Portfolio } from './components/portfolio/Portfolio';
import { Header } from './components/Header/Header';
import { Login } from './components/accessory/Login';
import { useAxiosConfig } from './api/axios';
import { WaveDots } from './components/accessory/loading';
import { Leaderboard } from './components/leaderboard/Leaderboard';

const App = () => {
  useAxiosConfig()
  
  useEffect(() => {
    window.addEventListener('unhandledrejection', (event) => {
      console.warn(`Warning: ${event.reason}`);
      console.warn('event')
      console.warn(event)
      event.preventDefault();
    });
    window.addEventListener('error', (event) => {
      console.warn(`Warning: ${event}`);
      event.preventDefault();
    });
    window.addEventListener('DOMContentLoaded', (event) => {
      console.warn(`Warning: ${event}`);
      event.preventDefault();
    });
  }, []);

  const [assetId, setAssetId] = useState<number>(1)
  const { isLoading } = useAuth0();

  const CenteredLoader = () => {
    return (
      <div className="flex justify-center items-center h-screen">
        <WaveDots />
      </div>
    )
  }

  return (
    <ErrorBoundary>
      <Router>
        <Header assetId={assetId} />
        <Routes>
          <Route path="/assets/:assetId" element={ isLoading ? <CenteredLoader />: <AssetCard setHeaderAssetId={setAssetId} />} />
          <Route path="/" element={isLoading ? <CenteredLoader />: <Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/market-info" element={isLoading ? <CenteredLoader /> : <MarketInfo />} />
          <Route path="/air-quality/:assetId" element={isLoading ? <CenteredLoader />: <AirQuality setHeaderAssetId={setAssetId} />} />
          <Route path="/portfolio/:userId?" element={isLoading ? <CenteredLoader /> : <Portfolio />} />
          <Route path="/leaderboard" element={isLoading ? <CenteredLoader /> : <Leaderboard />} />
        </Routes>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
