import React, { useState, useEffect } from 'react';
import { LineChart, Line, YAxis, Tooltip, ResponsiveContainer, XAxis } from 'recharts';
import { TooltipProps } from 'recharts';
import { ChartTimeFrame, LineDataPoint } from '../../types/types';
import moment from 'moment';

interface StockChartProps {
  selectedTimeRange: ChartTimeFrame;
  data: LineDataPoint[];
  onCurrentPriceChange?: (price: number) => void;
}

const StockChart = ({ selectedTimeRange, data, onCurrentPriceChange }: StockChartProps) => {
  const [hoveredValue, setHoveredValue] = useState<number | null>(null);
  const [minY, setMinY] = useState(0)
  const [maxY, setMaxY] = useState(0)
  const dateFormat = timeFrameFormatMap[selectedTimeRange];

  useEffect(() => {
    var max = Math.max(...data.map((d) => d.value))
    var min = Math.min(...data.map((d) => d.value))
    // if (max < 1) max = 1
    // if (min < 1) min = 0
    setMaxY(max)
    setMinY(min)
  }, [data])

  useEffect(() => {
    const currentIndex = hoveredValue !== null ? hoveredValue : data.length - 1;
    if (!currentIndex || currentIndex === -1) return
    const currentPrice = data[currentIndex].value;
    if (onCurrentPriceChange) onCurrentPriceChange(currentPrice);
  }, [hoveredValue, data]);

  const tickFormatter = (tick: string) => {
    const formattedTime = moment(new Date(tick)).format(dateFormat);
    return formattedTime.replace('am', 'a').replace('p', 'p');
  };

  const lineColor = (data: LineDataPoint[]) => {
    if (!data || data.length < 2) {
      return 'var(--turquoise-green)'
    }
    const staticChange = data[0].value - data[data.length-1].value;
    return `var(${staticChange < 0 ? '--turquoise-green' : '--warm-orange'})`;
    
  }
  return (
    <div style={{ fontFamily: 'Arial, sans-serif', color: '#333' }} className="md:mt-[6.5rem] mt-[1.25rem]">
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={data}
          onMouseMove={(e) => {
            if (e) {
              setHoveredValue(e.activeTooltipIndex ?? null);
            }
          }}
          onMouseLeave={() => setHoveredValue(null)}
        >
          <YAxis hide={false} domain={[minY, maxY]} ticks={[minY, maxY]} padding={{'bottom': 10}} axisLine={false} orientation="right" />
          <XAxis 
            dataKey="date" 
            axisLine={false}
            padding={{'right': 10}}
            minTickGap={175}
            tickFormatter={tickFormatter}
          />
          <Tooltip content={<CustomTooltip />} />
          <Line 
            type="monotone" 
            dataKey="value"
            stroke={lineColor(data)} 
            dot={false} 
            strokeWidth={3} 
            activeDot={{ r: 6 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StockChart;


const timeFrameFormatMap: Record<ChartTimeFrame, string> = {
  'HOUR': 'h:mma',
  '5HOUR': 'h:mma',
  'DAY': 'ha',
  'WEEK': 'MM:D ddd'
};

const CustomTooltip: React.FC<TooltipProps<any, any>> = ({ active, payload, coordinate }) => {
  if (active && payload && payload.length > 0) {
    const localDate = moment(payload[0].payload.date);
    
    const timeString = localDate.format('hh:mm A');
    const dateString = localDate.format('MMMM D');

    return (
      <div>
          <div>{dateString}</div>
          <div>{timeString}</div>
      </div>
    );
  }
  return null;
};