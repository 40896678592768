export const hour24to12 = (hour: number): string => {
  if (hour < 0 || hour > 23) {
    return 'Invalid Hour';
  }

  if (hour === 0) {
    return '12a';
  } else if (hour < 12) {
    return `${hour}a`;
  } else if (hour === 12) {
    return '12p';
  } else {
    return `${hour - 12}p`;
  }
};


export const truncateString = (str:string, limit:number) => {
	if (!str) {
	  return str
	}
	if (str.length > limit) {
	  return str.slice(0, limit) + '...'
	} else {
	  return str
	}
  }

export const handleDecimalsOnValue = (value: string = "0"): any => {
  const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
  return (value.match(regex) ?? [""])[0];
};
