import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import StockChart from './StockChart';
import SideBarList from '../sideBarList/SideBarList';
import AssetListModal from '../modal/AssetListModal';
import { getAssets, getPriceChartData, getDividendChartData } from '../../api/api';
import AssetInfo from './AssetInfo';
import { ChartTimeFrame, LineDataPoint } from '../../types/types';
import { TimeRangeSelector } from './TimeRangeSelector';
import { motion } from 'framer-motion';
import { Asset } from '../../types/types';
import AssetHeader from './AssetHeader';
import ErrorMessage from '../error/ErrorMessage';
import NextDividends from '../nextDividends/NextDividends';
import AssetMainCard from '../chart/AssetMainCard';
import ChartContainer from '../chartContainer/ChartContainer';

interface AssetCardProps {
  setHeaderAssetId?: React.Dispatch<React.SetStateAction<number>>;
}

const AssetCard=({setHeaderAssetId}:AssetCardProps)=> {
  const { assetId } = useParams<{ assetId: string }>();
  const navigate = useNavigate();
  const [assets, setAssets] = useState<Asset[]>([]);
  const [priceData, setPriceData] = useState<LineDataPoint[]>([]);
  const [dividendData, setDividendData] = useState<LineDataPoint[]>([]);
  const [assetName, setAssetName] = useState<string>(' '); // space for skeleton / loading
  const [selectedPrice, setSelectedPrice] = useState<number>(0);
  const [selectedTimeRange, setSelectedTimeRange] = useState<ChartTimeFrame>('HOUR');
  const initialAssetId = assetId ? parseInt(assetId, 10) : 1;
  const [currentAssetId, setCurrentAssetId] = useState<number>(initialAssetId);
  const [error, setError] = useState<string | null>(null);
  const { isAuthenticated } = useAuth0();
  const [currentAsset, setCurrentAsset] = useState<Asset>()
  const [openAssetListModal, setOpenAssetListModal] = useState<boolean>(false);

  const fetchAssets = async () => {
    try {
      const assets = await getAssets(false);
      if (!assets) return
      setAssets(assets);
      const currentAsset = assets.find(asset => asset.id === currentAssetId)
      setCurrentAsset(currentAsset);
      if (!assetId && assets.length > 0) {
        setCurrentAssetId(assets[0].id);
      }
    } catch (error: any) {
      setError("Something went wrong! Failed to fetch assets");
    }
  };


  async function fetchPriceChartData(assetId: number, selectedTimeRange: string) {
    try {
      const chartData = await getPriceChartData(assetId, selectedTimeRange);
      setPriceData(chartData?.priceHistory || []);
      setAssetName(chartData?.assetName || '');
    } catch (error: any) {
      setError("Something went wrong! Failed to fetch price data");
    }

  }

  async function fetchDividendChartData(assetId: number, selectedTimeRange: string) {
    try {
      const chartData = await getDividendChartData(assetId, selectedTimeRange);
      setDividendData(chartData?.dividendHistory || []);
    } catch (error: any) {
      setError("Something went wrong! Failed to fetch dividend data");
    }
  }

  useEffect(() => {
    fetchAssets();
    fetchPriceChartData(currentAssetId, selectedTimeRange);
    fetchDividendChartData(currentAssetId, selectedTimeRange);
  }, [currentAssetId, selectedTimeRange, isAuthenticated]);

  useEffect(() => {
    if (setHeaderAssetId) setHeaderAssetId(currentAssetId)
  }, [currentAssetId]);

  const handleSelectAsset = (selectedAsset: Asset) => {
    setOpenAssetListModal(false);
    setCurrentAsset(selectedAsset)
    setCurrentAssetId(selectedAsset.id);
    navigate(`/assets/${selectedAsset.id}`);
  };

  const handleCurrentPriceChange = (price: number) => {
    setSelectedPrice(price)
  };

  if (error) {
    return <ErrorMessage message={error} />
  }

  return (
    <>
      <div className="flex flex-col md:flex-row md:flex-start h-full px-[1.5rem] md:px-[2rem] min-[800px]:px-[3rem] max-md:mt-[20px] md:pt-[2.19rem]">
        <div className="hidden md:flex md:w-[40%] min-[800px]:w-[40%] min-[800px]:min-w-[180px] min-[1180px]:w-[23%] min-[1250px]:w-[28%] md:max-w-[320px] md:overflow-hidden md:mb-[1.25rem]">
        <SideBarList assets={assets} showAirQuality={false} selectedAsset={currentAsset} handleSelectAsset={handleSelectAsset} />
        </div>
        {priceData && assets && assets.length > 0 && (
          <motion.div
            className="flex-1 flex-shrink-1 mb-5 md:mb-0 h-full order-1 md:order-2 md:ml-[5%] "
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.7, duration: 0.5 }}
          >
            <div className="flex flex-col min-[1180px]:items-center min-[1180px]:flex-row min-[1180px]:justify-between min-[1180px]:gap-[1rem]">
              <div className="min-[1180px]:basis-2/3 mb-[17px] md:mb-[20px] min-[1180px]:mb-0 ">
                <AssetHeader currentPrice={selectedPrice} assetName={assetName} assetId={assetId} />
              </div>
              <div className="min-[1180px]:basis-1/3  ">
                <NextDividends />
              </div>
            </div>
            <ChartContainer>
              <div className="w-full pb-[100px] md:mt-[99px]">
              <div className="w-full order-2 md:order-1 my-[1.25rem] md:hidden text-center">
              <AssetMainCard showAirQuality={false} open={openAssetListModal} setOpen={setOpenAssetListModal} showArrow={true} asset={currentAsset} />
              {assets && assets.length > 0 &&
                    <p className='text-sm p-4 text-gray-600'>
                      Latest asset prices
                    </p>}
            </div>
            <StockChart data={priceData} selectedTimeRange={selectedTimeRange} onCurrentPriceChange={handleCurrentPriceChange} />
            <div className='md:py-[3.12rem] my-[2.25rem]'>
              <TimeRangeSelector
                selectedTimeRange={selectedTimeRange}
                onTimeRangeChange={(interval: ChartTimeFrame) => setSelectedTimeRange(interval)}
              />
            </div>
            <AssetInfo assetId={currentAssetId} />
            <div className='md:text-[1.875rem] md:mt-[2.9rem] text-[1.25rem] my-[1.25rem] font-bold'>Dividends</div>
            <StockChart data={dividendData} selectedTimeRange={selectedTimeRange} />
              </div>
            </ChartContainer>
          </motion.div>
        )}
        <AssetListModal showAirQuality={false} handleSelectAsset={handleSelectAsset} assets={assets} open={openAssetListModal} setOpen={setOpenAssetListModal} currentAssetId={currentAssetId} />
      </div>
    </>
  );
}

export default AssetCard;