import '@fortawesome/fontawesome-free/css/all.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';

const AppWrapper = process.env.REACT_APP_API_BASE_URL === 'http://127.0.0.1:10000/' 
  ? <React.StrictMode><App /></React.StrictMode> 
  : <App />;

const Auth0Wrapper = () => (
  <Auth0Provider
  {...providerConfig}
  >
    {AppWrapper}
  </Auth0Provider>
);

const providerConfig:Auth0ProviderOptions = {
  domain:"dev-bbhs6iyj1zc2s7gz.us.auth0.com",
  clientId:"CYOwKnqHBC1e0CdcOhmfonuOzrn5TgY1",
  cacheLocation:"localstorage",
  authorizationParams: {
    redirect_uri: window.location.origin,
  },
};
const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Auth0Wrapper />
);

