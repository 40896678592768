import React, { useRef, useCallback } from 'react';
import { motion } from 'framer-motion';

type FlipSignProps = {
  text: string;
};

const FlipSign: React.FC<FlipSignProps> = ({ text }) => {
  const prevTextRef = useRef<string | null>(null);  // Initialize with null
  const handleAnimationComplete = useCallback(() => {
    prevTextRef.current = text;
  }, [text]);

  return (
    <div className="flex items-center">
      {text.split('').map((char, index) => {
        if (!/\d/.test(char)) {
          return null;
        }
        const prevChar = prevTextRef.current ? prevTextRef.current[index] : ' ';
        const parsedPrevChar = parseInt(prevChar, 10);
        const parsedChar = parseInt(char, 10);

        // If start and end numbers are the same, skip the animation
        if (parsedPrevChar === parsedChar) {
          return (
            <div key={index} className="h-7 mb-0.5">
              {parsedChar}
            </div>
          );
        }

        const sequence = generateNumberSequence(parsedPrevChar, parsedChar);

        return (
          <div key={index} className="overflow-hidden h-7">
            <motion.div
              key={`${char}-${index}`}  
              initial={{ y: 0 }}
              animate={{ y: sequence.length === 1 ? 0 : `-${yOffset(sequence.length)}%` }}
              transition={{
                duration: 2,
                ease: 'easeInOut',
              }}
              onAnimationComplete={handleAnimationComplete}
            >
              {sequence.map((num, i) => (
                <div key={i} className="h-7">
                  {num}
                </div>
              ))}
            </motion.div>
          </div>
        );
      })}
    </div>
  );
}

export default React.memo(FlipSign);

const yOffset = (sequenceLength: number): number => {
  let offset = sequenceLength * 10;
  if (sequenceLength === 2) {
    offset += sequenceLength * 16.90;
  } else if (sequenceLength === 3) {
    offset += sequenceLength * 12.80;
  } else if (sequenceLength === 4) {
    offset += sequenceLength * 9.15;
  } else if (sequenceLength === 5) {
    offset += sequenceLength * 6.25;
  } else if (sequenceLength === 6) {
    offset += sequenceLength * 4.00;
  } else if (sequenceLength === 7) {
    offset += sequenceLength * 2.40;
  } else if (sequenceLength === 8) {
    offset += sequenceLength * 0.99;
  } else if (sequenceLength === 9) {
    offset += sequenceLength * -0.08;
  } else if (sequenceLength === 10) {
    offset += sequenceLength * -0.92;
  } else if (sequenceLength === 11) {
    offset += sequenceLength * -1.67;
  } else if (sequenceLength === 12) {
    offset += sequenceLength * -2.0;
  }
  return offset
} 

const generateNumberSequence = (start: number | null, end: number): (number | string)[] => {
  const sequence: (number | string)[] = [];
  let current = start;
  
  if (start === null || isNaN(start)) {
    sequence.push(' ');
    current = 0;
    sequence.push(current);
    if (end === 0) {
      return sequence;
    }
  }

  if (current === end) {
    if (sequence.length) {
      return sequence;
    }
    return [end]; // If sequence is empty but start and end are the same, return an array with the end value
  }
  
  do {
    if (current !== null) {
      sequence.push(current);
    }
    current = (current! + 1) % 10;
  } while (current !== end);
  sequence.push(end);

  const dedupedSequence = removeAdjacentDuplicates(sequence);
  return dedupedSequence;
};

const removeAdjacentDuplicates = (arr: (number | string)[]): (number | string)[] => {
  const deduped: (number | string)[] = [];
  let lastElement: number | string | null = null;

  for (const element of arr) {
    if (element !== lastElement) {
      deduped.push(element);
    }
    lastElement = element;
  }
  return deduped;
};
