import React from 'react';
import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { getBalance, sellAsset, getUserAssets } from '../../api/trade';
import { buyAsset } from '../../api/trade';
import { formatAmount } from './utils';
import SellAssetForm from './SellForm';
import BuyAssetForm from './BuyForm';
import { handleDecimalsOnValue } from "../../helpers/helperFunctions";


type TradeModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  assetName: string;
  currentPrice: number;
  getPrice: (shares: number, price: number) => number;
  assetId?: string;
};

const TradeModal = ({ open, setOpen, assetName, currentPrice: currentAssetPrice, getPrice, assetId }: TradeModalProps) => {
  const cancelButtonRef = useRef(null);
  const [isBuying, setIsBuying] = useState(true);
  const [currentOwnedShares, setCurrentOwnedShares] = useState(0);
  const [balance, setBalance] = useState(0);
  const [error, setError] = useState("");

  const [inputShares, setInputShares] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const totalBuyPrice = getPrice(inputShares, currentAssetPrice);
  const formattedTotalPrice = formatAmount(totalBuyPrice);
  const [isLoading, setIsLoading] = useState(false);


  const handleSwitchBuySell = () => {
    setInputShares(0);
    setError("");
    setSuccessMessage("");
    setIsBuying(prev => !prev);
  }

  const fetchData = async () => {
    try {
      const [fetchedShares, fetchedBalance] = await Promise.all([
        getUserAssets(assetId),
        getBalance()
      ]);
      setCurrentOwnedShares(fetchedShares || 0);
      setBalance(fetchedBalance || 0);
      setError(""); // reset error
    } catch (error) {
      console.error(error);
      setError("Error fetching data");
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
    setInputShares(0);
    setError("");
    setSuccessMessage("");
  };


  const handleSellSharesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newShares = handleSharesChange(event);
    setError(newShares >= currentOwnedShares ? "Insufficient Shares" : "");
  };
 
  const handleBuySharesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newShares = handleSharesChange(event);
    setError(getPrice(newShares, currentAssetPrice) > balance ? "Insufficient Funds" : "");
  };

  const handleSharesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newShares = handleDecimalsOnValue(event.target.value);
    newShares = parseFloat(newShares.replace(/^0+(?!\.)/, ''));
    if (isNaN(newShares) || newShares === "" || newShares < 0) {
      setInputShares(0);
      return;
    }
    setInputShares(newShares);
    return newShares;
  }

  const handleBuy = async () => {
    setIsLoading(true);
    try {
      await buyAsset(assetId, totalBuyPrice);
      setSuccessMessage(`Successfully bought ${inputShares} shares of ${assetName} at ${formattedTotalPrice}`);
      setInputShares(0);
      fetchData();
    } catch (error) {
      console.error(error);
      setError("Error buying asset");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSell = async () => {
    setIsLoading(true);
    try {
      await sellAsset(assetId, inputShares);
      setSuccessMessage(`Successfully sold ${inputShares} shares of ${assetName} at ${formattedTotalPrice}`);
      setInputShares(0);
      fetchData();
    } catch (error) {
      console.error(error);
      setError("Error selling asset");
    } finally {
      setIsLoading(false);
    }
  };

  

  useEffect(() => {
    if (open) {
      fetchData();
    } else {
      setError("");
      setSuccessMessage("");
    }
  }, [open, isBuying]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto" aria-hidden="true" />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center md:items-center md:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-500"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all md:my-8 w-[390px] md:w-[700px]">
                <div className="bg-white px-[25px] md:px-[69px] py-[33px] md:py-[49px] md:p-8 pb-[31px] md:pb-[50px]">
                  <div className="md:flex md:items-start">
                    <div className="mt-3 text-left md:w-full md:mt-0">
                      <div className="divide-y">
                        <div className="flex flex-row justify-between mb-[30px]">
                          <Dialog.Title className="text-[18px] font-bold  md:text-[25px] text-[#424242] ">
                            {assetName}
                          </Dialog.Title>
                          <div className="flex flex-row justify-between  w-[100px] h-[37px]  overflow-hidden">
                            <h3 className={`cursor-pointer text-[12px] md:text-[12px] w-1/2 grid place-content-center rounded-tl-[10px] rounded-bl-[10px] border-[1px] ${isBuying ? 'text-white bg-[var(--primary-dodger-blue)] border-[var(--primary-dodger-blue)]' : 'text-[var(--text-color-primary)] border-[#ccc]'}`} onClick={handleSwitchBuySell}>
                              Buy
                            </h3>
                            <h3 className={`cursor-pointer text-[12px] md:text-[12px] w-1/2 grid place-content-center border-[1px] rounded-tr-[10px] rounded-br-[10px]   ${!isBuying ? 'text-white bg-[var(--primary-dodger-blue)] border-[var(--primary-dodger-blue)]' : 'text-[var(--text-color-primary)] border-[#ccc]' }`} onClick={handleSwitchBuySell}>
                              Sell
                            </h3>
                          </div>
                        </div>
                        <div></div>
                      </div>
                      {isBuying ?
                        <BuyAssetForm
                          currentPrice={currentAssetPrice}
                          cancelButtonRef={cancelButtonRef}
                          shares={inputShares}
                          handleSharesChange={handleBuySharesChange}
                          handleBuy={handleBuy}
                          handleCloseModal={handleCloseModal}
                          formattedTotalPrice={formattedTotalPrice}
                          totalBuyPrice={totalBuyPrice}
                          balance={balance}
                          currentOwnedShares={currentOwnedShares}
                          error={error}
                          successMessage={successMessage}
                          isLoading={isLoading}
                        /> :
                        <SellAssetForm
                          currentPrice={currentAssetPrice}
                          cancelButtonRef={cancelButtonRef}
                          shares={inputShares}
                          handleSharesChange={handleSellSharesChange}
                          handleSell={handleSell}
                          handleCloseModal={handleCloseModal}
                          formattedTotalPrice={formattedTotalPrice}
                          totalSellPrice={totalBuyPrice}
                          balance={balance}
                          currentOwnedShares={currentOwnedShares}
                          error={error}
                          successMessage={successMessage}
                          isLoading={isLoading}
                        />
                      }
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root >
  );
}
export default TradeModal;