import { motion } from "framer-motion";
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Asset } from '../../types/types';
import ModalItemCard from "../sideBarList/ModalItemCard";


type AssetListModalProps = {
  open?: boolean;
  assets?: Asset[];
  currentAssetId?: number;
  setOpen: (open: boolean) => void;
  showAirQuality?: boolean;
  handleSelectAsset: (selectedAsset: Asset) => void;
};

const AssetListModal = ({ showAirQuality, assets, open, currentAssetId, handleSelectAsset, setOpen }: AssetListModalProps) => {
  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" initialFocus={cancelButtonRef} open={open} onClose={() => setOpen(false)}>
      <div className="fixed inset-0 mt-8" aria-hidden="true" />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 mt-8">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-500"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden w-full max-w-sm rounded-[0.95rem] bg-white">
                <Dialog.Title className="text-center font-bold leading-4 text-xl text-gray-900 mb-3 p-5">
                  Assets {showAirQuality ? 'Air Quality' : 'Prices'}
                </Dialog.Title>
                <div className="flex flex-col max-h-[70vh] overflow-y-auto">
                  {assets && assets.map((asset, idx) => (
                    <motion.div
                      key={asset.id}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: idx * 0.01, duration: 0.25 }}  // Delay based on index
                      onClick={() => handleSelectAsset(asset)}
                      
                    >
                      <ModalItemCard asset={asset} showAirQuality={showAirQuality} />
                    </motion.div>
                  ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root >
  );
}

export default AssetListModal