import { useState } from 'react';
import { Table as MUITable, TableBody, TableContainer, Paper } from '@mui/material';
import { Column } from '../../types/types';
import TableHeader from './TableHeader'
import TableRow from './TableRow'
import { getStyleForTableContainer, leaderBoardTableBodyStyle, leaderBoardTableStyle } from './consts';
import moment from 'moment';

type TableProps = {
  data: any[];
  setData: React.Dispatch<React.SetStateAction<any[]>>;
  columns: Column[]
  noBorder?:Boolean
  isLeaderboard?:Boolean 
  _onRowClick?:(row:any) => void
}

function Table({ data, columns, setData, noBorder=false, isLeaderboard=false, _onRowClick }:TableProps) {  
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('');

  const convertBasedOnKey = (key:string,data:any)=>{
    if(key === 'date'){
      return moment.utc(data[key]).local()
    }else{
      return convertToNumber(data[key].replace('%', '').replace('$', '').replace('#',''))
    }
  }

  const handleSort = (key: string) => {
    const isAsc = orderBy === key && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(key);
    const sortedData = [...data].sort((a, b) => {
      let aValue = a.data && a.data[key] ? convertBasedOnKey(key,a.data) : 0;
      let bValue = b.data && b.data[key] ? convertBasedOnKey(key,b.data) : 0;
      return (aValue < bValue ? -1 : 1) * (newOrder === 'asc' ? 1 : -1);
    });
    setData(sortedData);
  };

  return (
      <TableContainer component={Paper} sx={getStyleForTableContainer(noBorder)}>
        <MUITable sx={isLeaderboard ? leaderBoardTableStyle :{}} className="rounded-[1.25rem]">
          <TableHeader isLeaderboard={isLeaderboard} columns={columns} orderBy={orderBy} order={order} handleSort={handleSort}  />
          <TableBody sx={isLeaderboard ? leaderBoardTableBodyStyle : {}}>
            {data.map((row, index) => (
              <TableRow key={row.id} row={row} columns={columns} _onRowClick={_onRowClick}/>
              ))}
          </TableBody>
        </MUITable>
      </TableContainer>
  );
}


export default Table;

const convertToNumber = (str: string) => {
  const lastChar = str.slice(-1);
  let num = parseFloat(str.slice(0, -1));

  switch (lastChar.toUpperCase()) {
    case 'K':
      num *= 1e3;
      break;
    case 'M':
      num *= 1e6;
      break;
    case 'B':
      num *= 1e9;
      break;
    case 'T':
      num *= 1e12;
      break;
    default:
      num = parseFloat(str);
      break;
  }

  return num;
};