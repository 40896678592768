import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react"
import TradeModal from "../modal/TradeModal";
import PromptModal from "../modal/promptModal/PromptModal";

interface AssetHeaderProps {
  currentPrice?: number;
  assetName: string;
  assetId?: string;
}

const AssetHeader = ({ currentPrice = 0, assetName, assetId }: AssetHeaderProps) => {
  const [open, setOpen] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const { isAuthenticated } = useAuth0();
  const getTradePrice = (shares: number, price: number) => {
    return shares * price;
  };

  const formattedPrice = typeof currentPrice === 'number' 
    ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(currentPrice) 
    : 'N/A';


  const handleBuyButton = () => {
    if (!isAuthenticated){ 
      setShowPrompt(true)
      return
    }
    setOpen(true)
  }

  return (
    <>
      <div className="flex flex-row justify-between items-center px-[1.25rem]  py-[2.14rem] bg-white md:mt-0 rounded-[0.9375rem] md:h-[10rem] md:py-[1.52rem] md:pl-[1.5rem] min-[1580px]:pl-[57px] md:pr-[22px] md:h-[144px]">
        <div >
          <h4 className="text-[1.875rem] font-semibold md:text-[25px] min-[1180px]:text-[20px]   min-[1350px]:text-[30px]">{assetName}</h4>
          {currentPrice !== 0 && <h2 className="text-[1.875rem] md:text-[25px] min-[1350px]:text-[30px] font-semibold min-[1580px]:text-[44px]">${formattedPrice}</h2>}
        </div>
        {assetName && assetName !== ' ' &&  <button onClick={() => handleBuyButton()} className="bg-[var(--primary-dodger-blue)] text-white font-bold py-[13px]  grid place-content-center  rounded-[12px] md:rounded-lg w-[72px]  md:w-[80px] min-[1580px]:w-[114px] h-[40px] md:h-[50px] ">
            Buy
          </button>}
      </div>
      <PromptModal show={showPrompt} onClose={()=>setShowPrompt(false)} />
      <TradeModal open={open} setOpen={setOpen} assetName={assetName} assetId={assetId} currentPrice={currentPrice} getPrice={getTradePrice} />
    </>
  );
}

export default AssetHeader;
