import { motion } from "framer-motion";
import FlipSign from '../accessory/FlipSign';
import { useNextDividends, useCountdownTime, useUpdateDividends } from "./hooks";

const NextDividends = () => {
  const [nextDividends, setNextDividends] = useNextDividends();
  const [minutes, seconds] = useCountdownTime();
  useUpdateDividends(setNextDividends);

  return (
    <motion.div
      initial={{ opacity: 0, y: -5 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex flex-row justify-between items-center bg-white p-[1.5rem] rounded-[0.9375rem]  md:py-8  md:px-[2.6rem] md:h-[144px]">
        <motion.div layoutId="pbx-next-dividend-timer"
          className="flex flex-cols pr-[0.8rem] items-center gap-[0.62rem] md:pr-[1.88rem]">
          <p className="text-[0.65rem] text-right pr-0 md:pr-[0.5rem] md:text-[0.75rem]">NEXT <br /> DIVIDEND</p>
          <p className="md:text-3xl font-semibold w-[40px] md:w-[80px]">{minutes}:{String(seconds).padStart(2, '0')}</p>
          
        </motion.div>
        <span className="min-h-[2rem] md:h-[4.125rem] md:min-h-[1rem] w-0.5 bg-[#CCC] md:h-[5.6875rem]"></span>
        <motion.div layoutId="pbx-next-dividend-value" 
              className="flex flex-cols  pl-[0.88rem] gap-[0.62rem] items-center md:pl-6">
          <p className="text-[0.65rem] md:text-[0.625rem] pr-[0.62]">EXPECTED <br />DIVIDENDS</p>
          <div className="md:text-3xl flex items-center font-semibold w-[80px] md:w-[150px]">
            <span>$</span>
            <FlipSign text={String(nextDividends)} />
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
}

export default NextDividends;